import { useRouteLoaderData } from '@remix-run/react';
import React from 'react';
import { $path, $routeId } from 'remix-routes';

import {
  type DtoPage,
  type DtoPageSection,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { useNavigateToSection } from '../DynamicPage/PageSectionContainer';
import { PageSectionNav } from '../DynamicPage/PageSectionNav';
import { PublicLibraryBackground } from './PublicLibraryBackground';

export interface AdminPublicLibraryData {
  pageName: EnumsPageName;
  page: DtoPage;
}

export function useAdminPublicLibraryData() {
  const data = useRouteLoaderData<AdminPublicLibraryData>(
    $routeId('routes/_shell-client.admin.public-library._shell.$name._shell')
  );
  if (!data) throw new Error('Explore data not found');
  return data;
}

export function AdminPublicLibraryLayout(props: {
  children: React.ReactNode;
  isSelectedSection?: (section: DtoPageSection) => boolean;
  actions?: React.ReactNode;
}) {
  const { isSelectedSection, children, actions } = props;
  const { page, pageName } = useAdminPublicLibraryData();

  const navigateToSection = useNavigateToSection(
    $path('/admin/public-library/:name', { name: pageName })
  );

  return (
    <div className='w-full h-full flex flex-col'>
      <div
        className='
            w-full h-15 flex-none
            bg-modal text-white border-b border-secondary  
            px-5 flex justify-between items-center gap-10
          '
      >
        <div className='flex-1 h-full overflow-hidden'>
          <PageSectionNav
            sections={page.sections || []}
            selectedSection={
              isSelectedSection ? page.sections?.find(isSelectedSection) : null
            }
            onSectionClick={(section) => navigateToSection(section)}
          />
        </div>

        {actions}
      </div>

      <div className='relative w-full flex-1 overflow-hidden bg-modal text-white'>
        <PublicLibraryBackground page={page} />

        <div className='relative w-full h-full overflow-auto scrollbar'>
          {children}
        </div>
      </div>
    </div>
  );
}
